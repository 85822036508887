<template>
  <EntityList
    :config="config"
    :columns="columns"
    :formatted-columns="formattedColumns"
    :filters="filters"
    :filter-vals.sync="filterVals"
    authority="Users_Partner_Request"
    single-edit
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'

import storeModule from '../partnerRequestStoreModule'

import requestStatusOptions from '../requestStatusOptions'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'partner-request',
        endpoint: 'partner-request',
        route: 'partner-requests',
        title: {
          single: this.$i18n.t('Partner Request'),
          plural: this.$i18n.t('Partner Requests'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'sender', label: this.$t('Sender'), sortable: false },
        { key: 'receiver', label: this.$t('Receiver'), sortable: false },
        { key: 'status', label: this.$t('Partnership Status'), sortable: false },
        { key: 'creationDate', label: this.$t('Creation Date'), sortable: true },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    formattedColumns() {
      return [
        { key: 'creationDate', type: 'datetime' },
        { key: 'status', type: 'translate' },
      ]
    },

    filters() {
      return [
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$i18n.t('Created From'),
          value: null,
          maxDateFieldIdx: '1',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$i18n.t('Created To'),
          value: null,
          minDateFieldIdx: '0',
          maxDate: new Date(),
        },
        {
          name: 'status',
          label: this.$t('Partnership Status'),
          options: requestStatusOptions,
          value: null,
        },
      ]
    },
  },
}
</script>
